import React, { useState } from 'react';
import './Portfolio.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function Portfolio() {
    const importAll = (r) => r.keys().map(r);
    const weddingImages = importAll(require.context('../../public/assets/images/portfolio-images/wedding', false, /\.(png|jpe?g|svg)$/));
    const kidImages = importAll(require.context('../../public/assets/images/portfolio-images/kids', false, /\.(png|jpe?g|svg)$/));
    const portraitImages = importAll(require.context('../../public/assets/images/portfolio-images/portrait', false, /\.(png|jpe?g|svg)$/));
    const landscapeImages = importAll(require.context('../../public/assets/images/portfolio-images/landscape', false, /\.(png|jpe?g|svg)$/));
    const familyImages = importAll(require.context('../../public/assets/images/portfolio-images/family', false, /\.(png|jpe?g|svg)$/));
    const [groupedPhotos, setGroupedPhotos] = useState([
        {
            name: 'Portrait',
            thumbnail: '/assets/images/portfolio-images/portrait/thumbnail.jpeg',
            photos: portraitImages
        },
        {
            name: 'Wedding',
            thumbnail: '/assets/images/portfolio-images/wedding/thumbnail.jpeg',
            photos: weddingImages
        },
        {
            name: 'Family',
            thumbnail: '/assets/images/portfolio-images/family/thumbnail.jpeg',
            photos: familyImages
        },
        {
            name: 'Kids',
            thumbnail: '/assets/images/portfolio-images/kids/thumbnail.jpeg',
            photos: kidImages
        },
        {
            name: 'Landscape',
            thumbnail: '/assets/images/portfolio-images/landscape/thumbnail.jpeg',
            photos: landscapeImages
        },
        // ... other groups
    ]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    // const handleGroupClick = (group) => {
    //     setSelectedPhotos(group.photos);
    // };
    const handleGroupClick = (group) => {
        setSelectedPhotos(group.photos);
        setIsOpen(true);
    };

    const handlePhotoClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    return (
        <div className="portfolio">
            <div className="groups-grid">
                {groupedPhotos.map(group => (
                    <div key={group.name} className="group" onClick={() => handleGroupClick(group)}>
                        <img src={group.thumbnail} alt={`${group.name} thumbnail`} className="group-thumbnail" loading="lazy" />
                        <h2>{group.name}</h2>
                    </div>
                ))}
            </div>
    
            {isOpen && (
                <Lightbox
                    mainSrc={selectedPhotos[photoIndex]}
                    nextSrc={selectedPhotos[(photoIndex + 1) % selectedPhotos.length]}
                    prevSrc={selectedPhotos[(photoIndex + selectedPhotos.length - 1) % selectedPhotos.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + selectedPhotos.length - 1) % selectedPhotos.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % selectedPhotos.length)}
                />
            )}
        </div>
    );
};

export default Portfolio;