import React from 'react';
import './About.css';

function About() {
    return (
        <div className="about">
            <h1>About Me</h1>
            <div className="about-content">
                <img src="/assets/images/About_photo.jpeg" alt="Profile" className="profile-picture" />
                <div className="about-text">
                    <p>Hello! I'm Michael, a professional photographer based in London. Photography is more than a profession for me, it's a passion, which is why I've spent more than 10 years capturing life's most precious moments. I specialize in portraits, weddings, landscapes. My approach to photography is to capture real moments, true emotions, and the essence of places and people. I'm committed to creating memories that last a lifetime. When I'm not behind the camera, I enjoy playing with my kids and listen to music.</p>
                    <p>Feel free to browse my portfolio to see some of my work, or contact me directly if you have any questions or would like to discuss a project.</p>
                </div>
            </div>
        </div>
    );
}

export default About;
