import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // for SPA navigation
import './Navbar.css';
import logo from './kk_logo.jpg';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);
    const handleCloseMenu = () => setIsMenuOpen(false);

    return (
        <nav className="navbar">
            <Link to="/" className="brand-name" onClick={handleCloseMenu}>
            <img src={logo} alt="Logo" style={{height: '40px', marginRight: '10px', borderRadius: '50%', objectFit: 'cover', verticalAlign: 'middle'}} />
                K&K Photography
            </Link>
            <div className="nav-items">
                <Link to="/" onClick={handleCloseMenu}>Home</Link>
                <Link to="/about" onClick={handleCloseMenu}>About</Link>
                <Link to="/portfolio" onClick={handleCloseMenu}>Portfolio</Link>
                <Link to="/contact" onClick={handleCloseMenu}>Contact</Link>
            </div>
            <div className="hamburger-container">
                <div className="hamburger" onClick={handleMenuToggle}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                {isMenuOpen && (
                    // <div className="mobile-nav">
                    <div className={`mobile-nav ${isMenuOpen ? 'open' : ''}`}>
                        <Link to="/" onClick={handleCloseMenu}>Home</Link>
                        <Link to="/about" onClick={handleCloseMenu}>About</Link>
                        <Link to="/portfolio" onClick={handleCloseMenu}>Portfolio</Link>
                        <Link to="/contact" onClick={handleCloseMenu}>Contact</Link>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
