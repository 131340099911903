import React, { useState } from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically send the data to a server or email service
        console.log('Form data submitted:', formData);
        alert('Thank you for your message!');
        setFormData({ name: '', email: '', message: '' }); // Reset form after submission
    };

    return (
        <div className="container">
            <div className="photos">
                <img src="/assets/images/contact/contact1.jpg" alt="Image 1" />
            </div>
            <div className="contact">
                <h1>Contact</h1>
                <div className='contact-info'>
                    <p><FontAwesomeIcon icon={faPhone} /> <strong>Phone:</strong> +44 7754 370985</p>
                    <p><FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> michael.bui@knkphotography.co.uk</p>
                    <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Address:</strong> 4 Helgiford Gardens, Sunbury-on-Thames, TW16 7NB</p>
                </div>
                <p>If you have any questions or would like to book a session, please fill out the form below.</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
                    </div>
                    <button type="submit">Send Message</button>
                </form>
            </div>
        </div>
    );
}

export default Contact;

