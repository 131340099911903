// Home.jsx
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Home.css';


function Home() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
      };      

    return (
        <div className="home">
            <div className="background-image">
                <div className="overlay-text">
                <h1>Capturing Moments, Crafting Memories</h1>
                <p>Explore the World Through a Unique Lens</p>
                </div>
            </div>
            <section className="featured-works">
                <div className="gallery">
                    {Array(12).fill().map((_, i) => (
                        <img className="hover-effect" src={`/assets/images/picture_${i+1}.jpeg`} alt={`Featured Work ${i+1}`} key={i} />
                    ))}
                </div>
                <a href="/portfolio" className="explore-more">Explore More</a>
            </section>

        </div>
    );
}

export default Home;